<template>
  <div>
          <!-- Hero -->
    <base-page-heading title="Roles" subtitle="| Administracion de Roles">
      <template #extra>
        <b-breadcrumb class="breadcrumb-alt">
          <b-breadcrumb-item href="#/users/list">Roles</b-breadcrumb-item>
          <b-breadcrumb-item active>Administrador</b-breadcrumb-item>
        </b-breadcrumb>
      </template>
    </base-page-heading>
    <!-- END Hero -->
  </div>
</template>

<script>
export default {

}
</script>

<style>

</style>